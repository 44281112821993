import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { isEmpty } from "lodash";

import { api, credoraxKeyCreation, fetchUserCountryApi, fetchUserIpApi } from "@tint_fe/api";
import { updatePassportDetailsSaga } from "@tint_fe/redux-utils/src/order/orderSagas";
import {
  checkCreatingOrderError,
  CustomError,
  QueryString,
  bookingTypes,
  paymentMethods,
  getGclIdFromLocalStorage,
} from "@tint_fe/helpers";
import { getPaymentsLinks, getSourceLink, getSuccessPageUrl } from "../../utils";
import {
  getOrderApi,
  createOrderApi,
  createSmartPackageOrder,
  getAvailableExtrasApi,
  updateExtrasApi,
  updateOrderProductItemApi,
  updateCustomerDetailsApi,
  requestPaymentApi,
  initPaymentForm,
  requestProcessPaymentForm,
  requestPaymentContinueApi,
  sendDiscountCode,
  postCreateAgentPayment,
} from "../../api/order";
import * as types from "./orderActionTypes";
import * as globalTypes from "../global/globalActionTypes";
import * as productTypes from "../product/productActionTypes";
import * as smartPackageTypes from "../smartPackage/smartPackageTypes";
import { createNewOrderSearchParams } from "../helpers";
import { steps, paymentProviders, updateRatesTypes } from "../../helpers/constants";
import {
  SMART_PACKAGE_CUSTOMER,
  PASSPORT,
  ADDITIONAL_PAYMENT,
  FEEDBACK,
  ERROR,
  getPathByProductType,
  PAYMENT,
  CHECKOUT,
  CUSTOMER,
  getCustomerPathByBuildType,
} from "../../react/router";
import { setUuidToSessionStorage } from "../../storage";
import { fetchPricesSaga } from "../global/globalSagas";
import { selectError } from "../sharedSelectors";
import { restoreCashedSPExpiredTime } from "../smartPackage/smartPackageSagas";
import { ga_purchase_failure } from "@tint_fe/helpers/services/google-analytics-v2";
const ACT_LIKE_EXTRA_PAYMENT_ORDER_STATE = ["passport", "completed", "confirmed"];

function* createTourOrderSaga(action) {
  const { body, history, productType } = action.payload;
  const { search } = document.location;
  const isRedirect = productType === steps.TOUR_REDIRECT;

  try {
    const agentNumber = api.getAgentNumber();
    const orderBody = agentNumber ? { order: { ...body.order, agent_number: agentNumber } } : body;
    const response = yield call(createOrderApi, orderBody);
    const { number, state, uuid, product_items = [], build_type } = response.order || {};
    const productId = product_items.length ? product_items[0].product_id : null;
    const newSearch = createNewOrderSearchParams(search, number);

    setUuidToSessionStorage(uuid);

    if (isRedirect) {
      yield put({ type: productTypes.GET_PRODUCT_REQUEST, payload: { id: productId } });
      yield put({ type: globalTypes.FETCH_PRICES_REQUEST, payload: { number } });
    }

    yield put({
      type: types.GET_AVAILABLE_EXTRAS_REQUEST,
      payload: { orderId: number, productItemId: product_items[0].id },
    });

    const stateBasedOnAction = state;
    const redirectFunction = isRedirect ? history.replace : history.push;

    yield put({ type: globalTypes.SET_STEP_ACTION, payload: stateBasedOnAction });

    if (state === steps.CUSTOMER || isRedirect) {
      const isExtraStep = state === steps.EXTRAS;
      redirectFunction({
        pathname: getPathByProductType({ to: isExtraStep ? CHECKOUT : CUSTOMER, type: build_type }),
        search: newSearch,
      });
    } else {
      history.replace({ search: newSearch });
    }

    yield put({ type: types.CREATE_TOUR_ORDER_SUCCESS, payload: response.order });
  } catch (err) {
    yield put({ type: types.ORDER_REQUEST_FAILED, payload: err });
  }
}

function* createActivityOrderSaga(action) {
  const { body, history, productType } = action.payload;
  const { search } = document.location;

  try {
    const agentNumber = api.getAgentNumber();
    const orderBodyWithCurrency = {
      ...body,
      order: {
        ...body.order,
        charge_currency: api.currency,
      },
    };
    const orderBody = agentNumber
      ? { order: { ...orderBodyWithCurrency.order, agent_number: agentNumber } }
      : orderBodyWithCurrency;
    const response = yield call(createOrderApi, orderBody);
    const { number, state, uuid, product_items = [], build_type } = response.order || {};
    const productId = product_items.length ? product_items[0].product_id : null;
    const newSearch = createNewOrderSearchParams(search, number);

    setUuidToSessionStorage(uuid);

    if (productType === steps.ACTIVITY_REDIRECT) {
      yield put({ type: productTypes.GET_PRODUCT_REQUEST, payload: { id: productId } });
    }

    yield put({ type: types.CREATE_ACTIVITY_ORDER_SUCCESS, payload: response.order });
    yield put({ type: globalTypes.SET_STEP_ACTION, payload: state });
    yield call(fetchPricesSaga, { payload: { number: response.order.number, build_type: response.order.build_type } });

    const redirectFunction = productType === steps.ACTIVITY_REDIRECT ? history.replace : history.push;

    redirectFunction({
      pathname: getPathByProductType({ to: CUSTOMER, type: build_type }),
      search: newSearch,
    });
  } catch (err) {
    if (productType === steps.ACTIVITY_REDIRECT) {
      const newSearch = QueryString.parse(search);
      delete newSearch.activity;

      const id = body?.order?.product_items_attributes?.[0].product_id;

      if (id) {
        yield put({
          type: productTypes.GET_PRODUCT_REQUEST,
          payload: { id },
        });
      }

      history.replace({
        pathname: getPathByProductType({
          to: CHECKOUT,
          type: bookingTypes.ACTIVITY,
        }),
        search: QueryString.stringify(newSearch),
      });
    }

    yield put({
      type: types.ORDER_REQUEST_FAILED,
      payload: { ...err, step: steps.CHECKOUT, preventGlobalErrorRedirect: true },
    });
  }
}

function* createHotelOrderSaga(action) {
  const { body, productType, history } = action.payload;
  const { search } = document.location;
  try {
    const agentNumber = api.getAgentNumber();
    const orderBody = agentNumber ? { order: { ...body.order, agent_number: agentNumber } } : body;

    const response = yield call(createOrderApi, orderBody);
    const { number, uuid, product_items = [], build_type } = response.order || {};
    const productId = product_items.length ? product_items[0].product_id : null;
    const newSearch = createNewOrderSearchParams(search, number);

    yield setUuidToSessionStorage(uuid);

    if (productType === steps.HOTEL_REDIRECT) {
      yield put({ type: productTypes.GET_PRODUCT_REQUEST, payload: { id: productId } });
      yield put({ type: globalTypes.FETCH_PRICES_REQUEST, payload: { number } });
    }

    yield put({ type: types.CREATE_HOTEL_ORDER_SUCCESS, payload: response.order });
    yield put({ type: globalTypes.SET_STEP_ACTION, payload: steps.CUSTOMER });

    const redirectFunction = productType !== steps.HOTEL_REDIRECT ? history.push : history.replace;

    redirectFunction({
      pathname: getPathByProductType({ to: CUSTOMER, type: build_type }),
      search: newSearch,
    });
  } catch (err) {
    console.error(err);
    yield put({ type: types.ORDER_REQUEST_FAILED, payload: err });
  }
}

function* createSmartPackageOrderSaga(action) {
  yield put({ type: types.ORDER_PROCESSING });

  try {
    const agentNumber = api.getAgentNumber();
    const { body, push, autoAccommodation = false } = action.payload;
    const { search } = document.location;
    const { sequence_code } = yield select((state) => state.smartPackage.data);
    const isAvailabilityRedirect = body.productType === steps.SMART_PACKAGE_REDIRECT;

    if (isAvailabilityRedirect) {
      yield put({ type: smartPackageTypes.GET_SMART_PACKAGE_REQUEST, payload: { id: body?.order?.package_id } });
    }

    const response = yield call(createSmartPackageOrder, {
      order: {
        ...body.order,
        ...(!autoAccommodation && { sequence_code }),
        source_link: getSourceLink(),
        agent_number: agentNumber,
      },
    });

    yield call(restoreCashedSPExpiredTime);
    const newSearch = yield createNewOrderSearchParams(search, response.order.number);

    yield setUuidToSessionStorage(response.order.uuid);
    yield call(fetchPricesSaga, { payload: { number: response.order.number, build_type: response.order.build_type } });
    yield put({
      type: types.CREATE_SMART_PACKAGE_ORDER_SUCCESS,
      payload: {
        ...response.order,
        arrival_extra_nights: response.order?.arrival_extra_nights || 0,
        departure_extra_nights: response.order?.departure_extra_nights || 0,
      },
    });
    yield put({ type: globalTypes.SET_STEP_ACTION, payload: steps.CUSTOMER });

    yield push({ pathname: SMART_PACKAGE_CUSTOMER, search: newSearch });
  } catch (err) {
    yield checkCreatingOrderError({
      err,
      tryAgain: function* () {
        yield put({
          type: types.CREATE_SMART_PACKAGE_ORDER_REQUEST,
          payload: action.payload,
        });
      },
      onError: function* () {
        yield put({
          type: types.ORDER_REQUEST_FAILED,
          payload: err,
        });
      },
    });
  }
}

export function* getOrderSaga({ payload }) {
  try {
    const { orderNumber, onSuccess } = payload;
    const response = yield call(getOrderApi, orderNumber);
    const order = response.order || {};
    yield put({ type: types.GET_ORDER_SUCCESS, payload: order });

    yield put({ type: globalTypes.FETCH_PRICES_REQUEST, payload: { number: orderNumber } });

    if (onSuccess) {
      onSuccess(order);
    }
  } catch (err) {
    console.error(err);
    yield put({ type: types.GET_ORDER_FAILED, payload: err });
  }
}

function* getOrderWithProductsSaga(action) {
  const { orderId, location, source, replace } = action.payload;

  try {
    const failedPayment = yield select(selectError);
    const paymentProvider = yield select((state) => state.global.integration.payment_provider);
    const updatingRates = yield select((state) => state.global.ratesWasExpired.updating);
    const shouldRedirectToPayment =
      (failedPayment && paymentProvider === paymentProviders.CREDORAX) || source === "staff_payment";

    const { order = {} } = yield call(getOrderApi, orderId);
    const isPackage = !!order.package_id;
    const isSuccess = [steps.PASSPORT, steps.CONFIRMED, steps.COMPLETED].includes(order.state);

    yield call(fetchPricesSaga, {
      payload: { number: order.number, build_type: order.build_type },
    });

    if (source === "incomplete_email" && order.build_type !== bookingTypes.TOUR && !updatingRates) {
      yield put({
        type: globalTypes.UPDATE_RATES_REQUEST,
        payload: {
          usedReassessLink: true,
          updateType: updateRatesTypes.ORDER,
        },
      });
    }

    if (isPackage) {
      yield put({
        type: smartPackageTypes.GET_SMART_PACKAGE_REQUEST,
        payload: { id: order.package_id, query: { start_date: order.product_items[0]?.start_at } },
      });
    }

    const isSingleProduct = order?.product_items.length === 1;
    const productItem = order?.product_items[0] || {};
    const { prevStep } = location.state || {};

    if (isSingleProduct && !isPackage) {
      yield put({
        type: types.GET_AVAILABLE_EXTRAS_REQUEST,
        payload: { orderId: order.number, productItemId: productItem.id },
      });

      yield put({
        type: productTypes.GET_PRODUCT_REQUEST,
        payload: { id: productItem.product_id },
      });
    }

    yield put({ type: types.GET_ORDER_SUCCESS, payload: order });

    switch (true) {
      case source === steps.REQUEST_PAYMENT:
        return replace(ADDITIONAL_PAYMENT);
      case shouldRedirectToPayment:
        return replace(getPathByProductType({ to: PAYMENT, type: order.build_type }));
      case source === "passport_email":
        return replace(PASSPORT);
      case source === "feedback_email":
        return replace(FEEDBACK);

      case isSuccess:
        return replace(`/${order.state}`);

      case isSingleProduct &&
        (([steps.CUSTOMER, steps.PAYMENT].includes(order.state) && prevStep === steps.CUSTOMER) ||
          [steps.CHECKOUT, steps.EXTRAS].includes(order.state)):
        yield put({ type: globalTypes.SET_STEP_ACTION, payload: order.state });

        return replace(getPathByProductType({ to: CHECKOUT, type: order.build_type }));
      default:
        return replace(getPathByProductType({ to: CUSTOMER, type: order.build_type }));
    }
  } catch (err) {
    console.error(err);
    replace(ERROR);
    yield put({ type: types.GET_ORDER_FAILED, payload: err });
  }
}

function* getAvailableExtrasSaga(action) {
  try {
    const response = yield call(getAvailableExtrasApi, action.payload);

    yield put({
      type: types.GET_AVAILABLE_EXTRAS_SUCCESS,
      payload: response.product_item.available_product_extras || [],
    });
  } catch (err) {
    console.error(err);
    yield put({ type: types.GET_AVAILABLE_EXTRAS_FAILED, payload: err });
  }
}

function* updateExtrasSaga(action) {
  try {
    const { history, reset, ...rest } = action.payload;
    const order = yield select((state) => state.order.data);
    const orderId = order.number;
    const productItemId = order.product_items?.[0]?.id;

    yield call(updateExtrasApi, { ...rest, orderId, productItemId });

    const response = yield call(getOrderApi, orderId);

    if (!reset) {
      history.push(getCustomerPathByBuildType(order.build_type));
      yield put({ type: globalTypes.SET_STEP_ACTION, payload: steps.CUSTOMER });
    }

    yield put({ type: types.GET_ORDER_SUCCESS, payload: response.order });
    yield put({ type: types.UPDATE_EXTRAS_SUCCESS });
  } catch (err) {
    console.error(err);
    yield put({ type: types.UPDATE_EXTRAS_FAILED });
  }
}

function* updateOrderProductItemSaga(action) {
  try {
    const prevStep = yield select((state) => state.global.step);
    const order = yield select((state) => state.order.data);
    const orderId = order.number;
    const productItemId = order?.product_items?.[0]?.id;
    const { body, history } = action.payload;
    yield call(updateOrderProductItemApi, { ...action.payload, orderId, productItemId });

    const response = yield call(getOrderApi, orderId);

    const productItem = response.order.product_items[0];

    const { id, extra_line_items_attributes } = body.product_item;
    yield put({
      type: globalTypes.FETCH_PRICES_REQUEST,
      payload: {
        order: {
          number: orderId,
          product_items_attributes: { 0: { id, extra_line_items_attributes } },
        },
      },
    });

    const extras = yield call(getAvailableExtrasApi, {
      orderId: orderId,
      productItemId: productItem.id,
    });
    const { available_product_extras = [] } = extras.product_item || {};
    yield put({ type: types.GET_AVAILABLE_EXTRAS_SUCCESS, payload: available_product_extras });

    yield put({ type: types.UPDATE_ORDER_PRODUCT_ITEM_SUCCESS });

    const nextStep = prevStep === steps.CHECKOUT && available_product_extras.length ? steps.EXTRAS : steps.CUSTOMER;

    if (nextStep === steps.CUSTOMER) {
      history.push(getCustomerPathByBuildType(order.build_type));
    }

    yield put({ type: globalTypes.SET_STEP_ACTION, payload: nextStep });

    yield put({ type: types.GET_ORDER_SUCCESS, payload: response.order });
  } catch (err) {
    yield put({ type: types.UPDATE_ORDER_PRODUCT_ITEM_FAILED, payload: err });
  }
}

function* updateCustomerDetailsSaga(action) {
  try {
    const country = yield call(fetchUserCountryApi);
    const payload = action.payload;
    if (country) {
      payload.customerBody.order.customer_attributes.country = country;
    }
    const GLCID = getGclIdFromLocalStorage();
    if (GLCID) {
      payload.customerBody.order.customer_attributes.gclid = GLCID;
    }

    const response = yield call(updateCustomerDetailsApi, payload);

    yield call(fetchPricesSaga, { payload: { number: action.payload.orderId } });

    const order = response.order;
    yield put({ type: types.GET_ORDER_SUCCESS, payload: order });

    yield put({ type: types.UPDATE_CUSTOMER_DETAILS_SUCCESS });

    if (action.payload.callback) {
      yield call(action.payload.callback);
    }
  } catch (err) {
    yield put({ type: types.UPDATE_CUSTOMER_DETAILS_FAILED, payload: err });

    return err;
  }
}

function* createPaymentSaga(action) {
  yield put({ type: types.CREATE_PAYMENT_PROCESSING });

  try {
    const { orderId, customerBody, paymentBody, history } = action.payload;

    try {
      const customerResponse = yield call(updateCustomerDetailsSaga, { payload: { orderId, customerBody } });
      if (customerResponse.status === 500) {
        yield put({ type: types.CREATE_PAYMENT_FAILED, payload: customerResponse });

        return;
      }
    } catch (err) {
      console.error(err);
    }

    const bookingType = yield select((state) => state.order.data.build_type);
    const paymentProvider = yield select((state) => state.global.integration.payment_provider);

    if (paymentProvider === paymentProviders.CREDORAX) {
      yield call(initPaymentRequest, { payload: { number: orderId } });

      return history?.push ? history.push(getPathByProductType({ to: PAYMENT, type: bookingType })) : null;
    }

    const response = yield call(requestPaymentApi, { orderId, paymentBody });

    if (response.order.payment_page_url) {
      window.location.replace(response.order.payment_page_url);
    } else {
      console.error(response);
    }
  } catch (err) {
    console.error(err);
    yield put({ type: types.CREATE_PAYMENT_FAILED, payload: err });
  }
}

export function* initPaymentRequest({ payload }) {
  yield put({ type: types.ORDER_PROCESSING });

  try {
    const { payment_uuid: search_payment_uuid } = QueryString.parse(document.location.search);
    const { number, payment_uuid, onSuccess } = payload || {};
    const { order } = yield call(initPaymentForm, {
      orderNumber: number,
      body: { order: { agree: true, payment_uuid: payment_uuid || search_payment_uuid || null } },
    });

    yield onSuccess && onSuccess();
    yield put({ type: types.CREATE_PAYMENT_SUCCESS, payload: order });
  } catch (err) {
    yield put({ type: types.ORDER_REQUEST_FAILED, payload: err });

    return err;
  }
}

function* requestPaymentSaga({ payload }) {
  yield put({ type: types.REQUEST_PAYMENT_PROCESSING });
  try {
    const { success_url: query_success_url, source } = QueryString.parse(document.location.search);

    const {
      onSuccess,
      credit_card_id,
      save_credit_card,
      partial_payment,
      payment_method,
      token,
      threeds_required,
      cardholder_name,
      ...rest
    } = payload || {};

    const orderState = yield select(({ order }) => order?.data?.state);
    const dataForPayment = yield select(({ order }) => order.dataForPayment);

    const response = yield !(credit_card_id || payment_method !== paymentMethods.CREDIT_CARD) &&
      call(credoraxKeyCreation, { ...dataForPayment, ...rest });

    const { success_url, fail_url } = getPaymentsLinks({
      orderNumber: dataForPayment.number,
      paymentMethod: payment_method,
      shouldBackToOriginSource: ACT_LIKE_EXTRA_PAYMENT_ORDER_STATE.includes(orderState),
    });

    const ip_address = yield call(fetchUserIpApi);
    const screen_height = window.screen.height;
    const screen_width = window.screen.width;

    const { order } = yield call(requestProcessPaymentForm, {
      orderNumber: dataForPayment.number,
      payment_uuid: dataForPayment.payment_uuid,
      body: {
        order: {
          z2: 0,
          ...response,
          ...(credit_card_id && { credit_card_id }),
          ...(save_credit_card && { save_credit_card }),
          ...(cardholder_name && { cardholder_name }),
          threeds_required,
          success_url: query_success_url || success_url,
          fail_url,
          partial_payment: partial_payment === "partial",
          split_payment: partial_payment === "split",
          payment_method,
          token,
          ip_address,
          screen_height,
          screen_width,
        },
      },
    });

    if (order?.url) {
      return (window.location.href = order.url);
    }

    if (source === "staff_payment") {
      return (window.location.href = query_success_url);
    }

    yield put({ type: types.REQUEST_PAYMENT_SUCCESS, payload: { payment_method } });
    yield put({ type: types.GET_ORDER_REQUEST, payload: { orderNumber: dataForPayment.number } });
    yield onSuccess && onSuccess(getSuccessPageUrl());
  } catch (err) {
    const dataForPayment = yield select(({ order }) => order.dataForPayment);
    const productData = yield select(({ product }) => product?.data);

    console.error(err);
    const error = err instanceof CustomError ? { messages: [err.message] } : err;

    ga_purchase_failure(productData, dataForPayment.price_to_be_charged, error?.messages?.[0]);

    yield call(initPaymentRequest, { payload: { number: dataForPayment.number } });
    yield put({ type: types.REQUEST_PAYMENT_FAILED, payload: error });
  }
}

function* createUserAgentPaymentSaga({ payload }) {
  const { onSuccess } = payload;
  yield put({ type: types.REQUEST_PAYMENT_PROCESSING });
  try {
    const dataForPayment = yield select(({ order }) => order.dataForPayment);

    yield call(postCreateAgentPayment, { orderNumber: dataForPayment.number });

    yield put({ type: types.CREATE_USER_AGENT_PAYMENT_SUCCESS });
    yield put({ type: types.GET_ORDER_REQUEST, payload: { orderNumber: dataForPayment.number } });
    yield onSuccess && onSuccess(getSuccessPageUrl());
  } catch (err) {
    console.error(err);
    const error = err instanceof CustomError ? { messages: [err.message] } : err;
    yield put({ type: types.CREATE_USER_AGENT_PAYMENT_FAILED, payload: error });
  }
}

function* requestPaymentContinueSaga({ payload }) {
  try {
    const { history, ...rest } = payload || {};
    const paymentProvider = yield select((state) => state.global.integration.payment_provider);
    const bookingType = yield select((state) => state.order.data.build_type);

    if (paymentProvider === paymentProviders.CREDORAX) {
      const res = yield call(initPaymentRequest, {
        payload: { number: rest.orderId, payment_uuid: rest.paymentBody?.order?.payment_uuid },
      });

      if (res?.status && res?.status !== 200) return;

      yield call(fetchPricesSaga, { payload: { number: rest.orderId } });

      return history.push(getPathByProductType({ to: PAYMENT, type: bookingType }));
    }

    const response = yield call(requestPaymentContinueApi, rest);

    if (response.order.payment_page_url) {
      window.location.replace(response.order.payment_page_url);
    } else {
      console.error(response);
    }
  } catch (err) {
    console.error(err);
    yield put({ type: types.CREATE_PAYMENT_FAILED, payload: err });
  }
}

function* sendDiscountCodeSagas({ payload }) {
  const { onSuccess, onError, pricePayload, order } = payload;
  try {
    const orderNumber = yield select((state) => state.order.data.number);
    const dataForPayment = yield select((state) => state.order.dataForPayment);
    const res = yield call(sendDiscountCode, { orderId: orderNumber, order });

    if (!isEmpty(dataForPayment)) {
      yield call(initPaymentRequest, {
        payload: { number: orderNumber, payment_uuid: dataForPayment.payment_uuid },
      });
    }

    yield call(fetchPricesSaga, {
      payload: pricePayload ? pricePayload : { number: orderNumber },
    });
    yield put({ type: types.GET_ORDER_SUCCESS, payload: res.order });

    yield onSuccess && onSuccess();
  } catch (err) {
    yield onError && onError(err);
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.CREATE_HOTEL_ORDER_REQUEST, createHotelOrderSaga),
    yield takeLatest(types.CREATE_TOUR_ORDER_REQUEST, createTourOrderSaga),
    yield takeLatest(types.CREATE_ACTIVITY_ORDER_REQUEST, createActivityOrderSaga),
    yield takeLatest(types.CREATE_SMART_PACKAGE_ORDER_REQUEST, createSmartPackageOrderSaga),
    yield takeLatest(types.GET_ORDER_REQUEST, getOrderSaga),
    yield takeLatest(types.GET_ORDER_WITH_PRODUCTS_REQUEST, getOrderWithProductsSaga),
    yield takeLatest(types.GET_AVAILABLE_EXTRAS_REQUEST, getAvailableExtrasSaga),
    yield takeLatest(types.UPDATE_EXTRAS_REQUEST, updateExtrasSaga),
    yield takeLatest(types.UPDATE_ORDER_PRODUCT_ITEM_REQUEST, updateOrderProductItemSaga),
    yield takeLatest(types.UPDATE_CUSTOMER_DETAILS_REQUEST, updateCustomerDetailsSaga),
    yield takeLatest(types.UPDATE_PASSPORT_DETAILS_REQUEST, updatePassportDetailsSaga),
    yield takeLatest(types.CREATE_PAYMENT_REQUEST, createPaymentSaga),
    yield takeLatest(types.INIT_PAYMENT_REQUEST, initPaymentRequest),
    yield takeLatest(types.REQUEST_PAYMENT, requestPaymentSaga),
    yield takeLatest(types.REQUEST_PAYMENT_CONTINUE_REQUEST, requestPaymentContinueSaga),
    yield takeLatest(types.SEND_DISCOUNT_CODE, sendDiscountCodeSagas),
    yield takeLatest(types.CREATE_USER_AGENT_PAYMENT_REQUEST, createUserAgentPaymentSaga),
  ]);
}

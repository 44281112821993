import * as React from "react";
const CheckMark = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#028C3E"
        fillRule="evenodd"
        d="M10.408 2.596a5.917 5.917 0 1 0 3.509 5.407V7.39a.75.75 0 0 1 1.5 0v.614a7.418 7.418 0 0 1-13.639 4.033 7.417 7.417 0 0 1 9.24-10.812.75.75 0 1 1-.61 1.37Zm4.789-.46a.75.75 0 0 1 0 1.06L8 10.402 5.47 7.87A.75.75 0 0 1 6.53 6.81L8 8.28l6.136-6.143a.75.75 0 0 1 1.06 0Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default CheckMark;

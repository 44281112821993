const LANG = "LNG";
const API_TESTING_URL = "API_TESTING_URL";
const GCLID_LS_KEY = "gclid";

export const setLngLocalStorage = (data) => localStorage.setItem(LANG, JSON.stringify(data));
export const getLngLocalStorage = () => JSON.parse(localStorage.getItem(LANG));
export const getGclIdFromLocalStorage = () => localStorage.getItem(GCLID_LS_KEY);

export const clearLocalStorage = () => localStorage.clear();

// FOR API TESTING ONLY
export const setApiUrlLocalStorage = (data) => localStorage.setItem(API_TESTING_URL, JSON.stringify(data));
export const getApiUrlLocalStorage = () => JSON.parse(localStorage.getItem(API_TESTING_URL));
export const removeApiUrlLocalStorage = () => localStorage.removeItem(API_TESTING_URL);

export * from "./jwtStorage";

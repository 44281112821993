import * as React from "react";
const FlagAU = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={15} {...props}>
    <style>{".b{fill:#fff}.c{fill:red}"}</style>
    <path
      d="M20 0v15H0V0z"
      style={{
        fill: "#00008b",
      }}
    />
    <path d="M1.2 0 5 2.8 8.8 0H10v1L6.3 3.8 10 6.5v1H8.8L5 4.7 1.3 7.5H0v-.9l3.7-2.8L0 1V0z" className="b" />
    <path
      d="M6.6 4.4 10 6.9v.6L5.8 4.4zm-2.9.3.1.5-3 2.3h-.9zM10 0 6.1 3v-.7L9.2 0zM0 0l3.7 2.7h-.9L0 .7z"
      className="c"
    />
    <path d="M6.3 0v7.5H3.8V0zM10 2.5V5H0V2.5z" className="b" />
    <path d="M10 3v1.5H0V3zM5.8 0v7.5H4.3V0z" className="c" />
    <path
      d="m16.5 12.4-.7.1.1.6-.5-.4-.4.4v-.6l-.6-.1.5-.3-.3-.6.6.2.2-.6.2.6.7-.2-.4.6zm-.1-3.7.1-.4-.3-.3h.4l.2-.4.1.4h.5l-.4.3.1.4-.3-.2zm-3.3-1.9-.6.1v.6l-.4-.4-.5.4.1-.6-.6-.1.5-.4-.3-.5.6.2.2-.6.2.6.6-.2-.3.6.5.3zm6.4-1-.7.1.1.6-.5-.4-.4.4v-.6l-.6-.1.5-.4-.3-.5.6.2.2-.6.3.6.6-.2-.4.5zm-3-2.6-.6.1v.6l-.4-.4-.5.4.1-.6-.7-.1.6-.3-.3-.6.6.2.2-.6.2.6.6-.2-.3.6zM7.3 12l-1.4.2.1 1.4-1-1-1 1 .1-1.4-1.4-.2 1.2-.8-.7-1.2 1.3.4.5-1.3.5 1.3 1.4-.4-.8 1.2 1.2.8z"
      className="b"
    />
  </svg>
);
export default FlagAU;

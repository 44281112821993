import * as React from "react";
const FlagCA = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={15} {...props}>
    <path
      d="M4.7 0h10.6v15H4.7z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      fillRule="evenodd"
      d="M4.7 0v15H-.6V0zm10.6 0h5.3v15h-5.3zM6 5.8c.4 0 .9.3 1.1.2.2-.1.3-.4.4-.6l1 1.1q.1 0 .2-.1l-.5-2.3.8.4h.1L9.9 3l.7 1.4q0 .1.1.1l.8-.4-.5 2.3q0 .1.2.1l1-1.1.3.5c.1.2 1.2-.1 1.2-.1-.1.5-.5 1.3-.2 1.5l.3.1L11.9 9c-.1.4 0 .6.2.8L10 9.6l.1 2.1h-.5l.1-2.1-2.1.3c.1-.3.3-.4.2-.8L5.9 7.4l.4-.1c.2-.3-.2-1.1-.3-1.5z"
      style={{
        fill: "#d52b1e",
      }}
    />
  </svg>
);
export default FlagCA;

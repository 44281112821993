import React from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { ABSharedSummary } from "@tint_fe/ui";

import {
  useActivitySummaryInfo,
  useAgentCommission,
  useDiscountInfo,
  useRouterMatch,
  useSendDiscount,
} from "../../../hooks";
import { bookingTypes } from "../../../helpers/constants";
import { useCheckIfBreakfastIncluded, useSPSummaryInfo } from "../../containers/SmartPackage/hooks";
import useTourSummaryInfo from "../../containers/Tour/hooks/useTourSummaryInfo";
import useHotelSummaryInfo from "../../containers/Hotel/hooks/useHotelSummaryInfo";
import useBundleSummary from "../../containers/Bundle/useBundleSummary";
import { selectIfCustomPrice } from "../../../redux/sharedSelectors";
import { SMART_PACKAGE_CHECKOUT, SMART_PACKAGE_SELECT_HOTELS, PAYMENT } from "../../router/routes";
import useTourStartLocation from "../../containers/Tour/hooks/useTourStartLocation";
import useActivityExtendedSummary from "../../containers/Activity/hooks/useActivityExtendedSummary";
import PolicyBtn from "../PolicyBtnModal/PolicyBtn";

const fixedPrice = (price) => Number(price).toFixed(0);

export const MobileBookingSummary = () => {
  const sendDiscountCode = useSendDiscount();
  const orderData = useSelector(({ order }) => order.data);
  const { showAgentCommission, showAgentPays, isCusomerPAC } = useAgentCommission({ order: orderData });

  const integration = useSelector((state) => state.global.integration);
  const price = useSelector((state) => state.global.price);
  const isCustomPrice = useSelector(selectIfCustomPrice);

  const displayName = useSelector((state) => state.product.data.display_name || state.smartPackage.data.display_name);
  const requestedPrice = useSelector(({ order }) => order.dataForPayment?.price_to_be_charged);

  const bookingType = useSelector(({ global, order }) => global.bookingType || order.data?.build_type);
  const roundedTaxAmount = price?.product_items && price?.product_items[0]?.rounded_citizenship_tax_amount;
  const lateBookingSupplement = price?.product_items && price?.product_items[0]?.rounded_late_booking_supplement;
  const citizenshipTaxAmount = Math.ceil(
    Number(roundedTaxAmount || price.citizenship_tax_amount || price.rounded_citizenship_tax_amount),
  );
  const spCurrency = useSelector((state) => state.smartPackage?.charge_currency);
  const spActuallCurrency = isEmpty(orderData) ? spCurrency : price?.charge_currency;
  const hotelCurrency = useSelector((state) => state.hotel?.charge_currency);
  const specificCurrency =
    bookingType === bookingTypes.SMART_PACKAGE
      ? spActuallCurrency
      : bookingType === bookingTypes.HOTEL
      ? hotelCurrency
      : null;
  const isNotBundle = bookingType !== bookingTypes.BUNDLE;

  const isPaymentPage = useRouterMatch(PAYMENT);
  const isSPCheckoutPage = useRouterMatch(SMART_PACKAGE_CHECKOUT);
  const isSPSelectHotelPage = useRouterMatch(SMART_PACKAGE_SELECT_HOTELS);
  const checkIfBreakfastIncluded = isNotBundle ? useCheckIfBreakfastIncluded() : () => false;
  const breakfastIncluded = checkIfBreakfastIncluded();

  const {
    lineItems: SPlineItems = [],
    rooms,
    hints: spHints = [],
    startLocation: SPStartLocation,
    endLocation: SPEndLocations,
    roundedTotalPrice: SPRoundedTotalPrice,
    totalPrice: SPTotalPrice,
    requestedPrice: SPRequestedPrice,
    hidePricesSection: SPHidePricesSection,
  } = useSPSummaryInfo();

  const tourLineItems = useTourSummaryInfo(price);

  const {
    lineItems: hotelLineItems = [],
    startLocation: hotelStartLocation,
    endLocation: hotelEndLocation,
    cancellation: hotelHint,
    roundedTotalPrice: hotelRoundedTotalPrice,
    taxes: hotelTaxes,
    descriptions: hotelDescriptions,
  } = useHotelSummaryInfo();

  const activityLineItems = useActivitySummaryInfo(price);

  const { startLocation: tourStartLocation } = useTourStartLocation();

  const { startLocation: activityStartLocation, ticketOption: activityTicketOption } = useActivityExtendedSummary();

  const { lineItems: bundleLineItems = [] } = useBundleSummary();

  const { showDiscount, discountOpenByDefault, discountPercent, discountAmount } = useDiscountInfo();

  const getLineItems = () => {
    switch (bookingType) {
      case bookingTypes.SMART_PACKAGE:
      case bookingTypes.CUSTOM_PACKAGE:
        return SPlineItems;
      case bookingTypes.HOTEL:
        return hotelLineItems;
      case bookingTypes.TOUR:
        return tourLineItems;
      case bookingTypes.BUNDLE:
        return bundleLineItems;
      case bookingTypes.ACTIVITY:
        return activityLineItems;
      default:
        return [];
    }
  };

  const computedLineItems = getLineItems();

  citizenshipTaxAmount &&
    computedLineItems.push({
      name: "Included Israel VAT",
      price: citizenshipTaxAmount,
      hideNumber: true,
    });

  const getPrice = () => {
    switch (true) {
      case isSPCheckoutPage:
        return null;
      case isSPSelectHotelPage:
        return SPRoundedTotalPrice;
      case bookingType === bookingTypes.SMART_PACKAGE:
        return (
          (SPHidePricesSection
            ? fixedPrice(price?.tint_charge_price)
            : fixedPrice(price?.rounded_taxed_components_price)) || hotelRoundedTotalPrice
        );
      case isCusomerPAC:
        return Math.ceil(
          Number(price.tint_charge_price || price.rounded_taxed_components_price || hotelRoundedTotalPrice),
        );
      default:
        return Math.ceil(Number(price.rounded_taxed_components_price || hotelRoundedTotalPrice));
    }
  };

  const getStartLocation = () => {
    switch (bookingType) {
      case bookingTypes.SMART_PACKAGE:
        return SPStartLocation;
      case bookingTypes.HOTEL:
        return hotelStartLocation;
      case bookingTypes.TOUR:
        return tourStartLocation;
      case bookingTypes.ACTIVITY:
        return activityStartLocation;
      default:
        return {};
    }
  };

  const getTicketOption = () => {
    switch (bookingType) {
      case bookingTypes.ACTIVITY:
        return activityTicketOption;
      default:
        return {};
    }
  };

  const getTaxes = () => {
    switch (bookingType) {
      case bookingTypes.HOTEL:
        return hotelTaxes;
      default:
        return {};
    }
  };

  const getDescriptions = () => {
    switch (bookingType) {
      case bookingTypes.HOTEL:
        return hotelDescriptions;
      default:
        return {};
    }
  };

  const getRequestedPrice = () => {
    if (requestedPrice > 0) return requestedPrice;
    if (SPRequestedPrice > 0) return SPRequestedPrice;

    return null;
  };

  const shouldHidePricesSection = () => {
    switch (bookingType) {
      case bookingTypes.SMART_PACKAGE:
        return isSPCheckoutPage || isSPSelectHotelPage ? false : SPHidePricesSection;
      default:
        return !!isCusomerPAC;
    }
  };

  const totalPrice = isSPSelectHotelPage ? SPTotalPrice : price?.tint_charge_price;
  const currency = specificCurrency ?? price?.charge_currency ?? integration?.charge_currency;

  return (
    <ABSharedSummary
      name={displayName}
      closeLabel={"Open summary"}
      lineItems={computedLineItems}
      lateBookingSupplement={lateBookingSupplement}
      showDiscount={showDiscount}
      discountOpenByDefault={discountOpenByDefault}
      percent={discountPercent}
      discountAmount={discountAmount}
      sendDiscountCode={sendDiscountCode}
      hints={[...spHints, hotelHint].filter(Boolean)}
      rooms={rooms}
      ticketOption={getTicketOption()}
      startLocation={getStartLocation()}
      endLocation={SPEndLocations || hotelEndLocation}
      {...(isPaymentPage && { partialPrice: price?.partial?.partial_price })}
      {...(isPaymentPage && { splitPrice: price?.split?.split_price })}
      totalPrice={totalPrice}
      requestedPrice={getRequestedPrice()}
      roundedTotalPrice={getPrice()}
      isCustomPrice={isNotBundle ? isCustomPrice : false}
      currency={currency}
      taxes={getTaxes()}
      descriptions={getDescriptions()}
      descriptionsComponent={isPaymentPage ? <PolicyBtn /> : null}
      agentFeeAmount={showAgentCommission ? price?.agent_fee_amount : 0}
      agentPaysAmount={showAgentPays ? price?.tint_charge_price : 0}
      breakfastIncluded={!isSPCheckoutPage && breakfastIncluded}
      hidePricesSection={shouldHidePricesSection()}
    />
  );
};

export default MobileBookingSummary;

import React, { useMemo } from "react";
import T from "prop-types";

import { validationRule, dates } from "@tint_fe/helpers";
import { useControlledInput } from "@tint_fe/hooks";
import { Input } from "../../../components";
import { Calendar } from "../../../assets/icons";

const normalizeDate = (value) => {
  if (value.length === 1 && Number(value) > 1) {
    return `0${value}/`;
  }

  if (value.length === 2 && Number(value[1]) > 2 && Number(value[0]) !== 0) {
    return "12/";
  }

  if (value[2] === "/" && value.length === 3) {
    return value;
  }

  return (
    value
      .replace(/\s/g, "")
      .replace(/[^0-9]/g, "")
      .match(/.{1,2}/g)
      ?.join("/")
      .substr(0, 5) || ""
  );
};

const makeKeepingCursorPosition = (placeholder) => {
  let prevValue = placeholder;

  return (evt, prevEvtParams) => {
    const newValue = evt.target.value;
    const oldCursor = prevEvtParams.cursor;

    const isDeleting = prevValue.length > newValue.length;
    const isCursorOnTheEnd = evt.target.value.length === evt.target.selectionEnd;

    if (!isDeleting && newValue.length === 4) {
      evt.target.selectionEnd = newValue.length + 1;
      evt.target.selectionStart = newValue.length + 1;

      return (prevValue = newValue);
    }

    if (isDeleting || isCursorOnTheEnd) {
      evt.target.selectionEnd = oldCursor;
      evt.target.selectionStart = oldCursor;

      return (prevValue = newValue);
    }
    prevValue = newValue;

    return (prevValue = newValue);
  };
};

export const DateInput = ({ control, ...rest }) => {
  const placeholder = "MM/YY";

  const keepCursorPosition = useMemo(() => makeKeepingCursorPosition(placeholder), []);

  const {
    field: { onChange, ...field },
    fieldState: { error },
    showValidateMark,
  } = useControlledInput({
    control,
    name: "card_expired",
    rules: {
      ...validationRule.required("paymentForm.expiryDate"),
      ...validationRule.minLength("paymentForm.expiryDate", 5),
      validate: (val) => {
        const date = dates.parse(val, "MM/yy");

        if (!dates.isFuture(date) && !dates.isSameMonth(date, new Date(), false)) {
          return "error.dateInput.pattern";
        }
      },
    },
    defaultValue: "",
  });

  return (
    <Input
      nextIcon={<Calendar />}
      label="paymentForm.expiryDate"
      name="card_expired"
      type="tel"
      placeholder={placeholder}
      inputMode="numeric"
      autoComplete="cc-exp"
      showValidateMark={showValidateMark}
      error={error}
      onChange={(event) => {
        const { value } = event.target;

        event.target.value = normalizeDate(value);

        return onChange(event);
      }}
      control={control}
      afterUpdate={keepCursorPosition}
      {...rest}
      {...field}
    />
  );
};

DateInput.propTypes = {
  control: T.object.isRequired,
};

export default DateInput;

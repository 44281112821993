import { QueryString } from "@tint_fe/helpers";
import { useSelector } from "react-redux";

import { selectDiscountPercent } from "../redux/global/globalSelectors";
import { bookingTypes, integrationsNames } from "../helpers/constants";
import { useIsLogged, useRouterMatch } from "@tint_fe/hooks";
import { CHECKOUT, SELECT_HOTELS } from "../react/router";

const useDiscountInfo = () => {
  const isLogged = useIsLogged();
  const { source } = QueryString.parse(document.location.search);
  const isCheckout = useRouterMatch(CHECKOUT);
  const isSelectHotels = useRouterMatch(SELECT_HOTELS);
  const integrationName = useSelector(({ global }) => global.integration.name);
  const integrationFieldOpened = useSelector(({ global }) => global?.integration?.discount_enabled);
  const discountable = useSelector(({ order }) => order.data.discountable);
  const discountPercentFromLineItems = useSelector(selectDiscountPercent);
  const discountPercentFromOrder = useSelector(({ order }) => order.data.discount_percent);
  const discountAmountFromOrder = useSelector(({ global }) => global.price?.discount_amount);

  const bookingType = useSelector(({ global }) => global.bookingType);

  const isSP = bookingType === bookingTypes.SMART_PACKAGE;
  const isTour = bookingType === bookingTypes.TOUR;
  const isHotel = bookingType === bookingTypes.HOTEL;

  const discountPercent = isSP ? discountPercentFromOrder : discountPercentFromLineItems;
  const overrideIntegrations = [integrationsNames.TOURIST_ITALY, integrationsNames.TOURIST_JAPAN];
  const isAllowedBookingType = isTour || isHotel || isSP;
  const isCorrectSource = source !== "request_payment" && !isCheckout && !isSelectHotels;
  const isDiscountable = discountable || (isLogged && !isSP) || overrideIntegrations.includes(integrationName);

  const showDiscount = isAllowedBookingType && isCorrectSource && isDiscountable;
  const discountAmount = Number(discountAmountFromOrder) || 0;

  return {
    showDiscount,
    discountOpenByDefault: !!integrationFieldOpened,
    discountPercent,
    discountAmount,
  };
};

export default useDiscountInfo;

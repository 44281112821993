import * as React from "react";

function SvgCalendarStart(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <g clipPath="url(#calendar_start_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 1a1 1 0 012 0v1h6V1a1 1 0 112 0v1h2.333c.368 0 .667.298.667.667V4H0V2.667C0 2.298.298 2 .667 2H3V1zm13 4H0v9a2 2 0 002 2h12a2 2 0 002-2V5zm-8.3 5a.7.7 0 01-.7-.7v-.6a.7.7 0 01.7-.7H13v2H7.7zM3 13v-2h9.3a.7.7 0 01.7.7v.6a.7.7 0 01-.7.7H3z"
          fill="#56483A"
        />
      </g>
      <defs>
        <clipPath id="calendar_start_svg__clip0">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgCalendarStart;

import React from "react";
import { useSelector } from "react-redux";

import { SharedSummary } from "@tint_fe/ui";
import { useRouterMatch } from "@tint_fe/hooks";

import { useSendDiscount, useDiscountInfo } from "../../hooks";
import { PAYMENT } from "../router/routes";

const OrderSummaryView = ({ ...rest }) => {
  const { showDiscount, discountPercent, discountAmount, discountOpenByDefault } = useDiscountInfo();
  const sendDiscountCode = useSendDiscount();
  const partial = useSelector(({ global }) => global.price?.partial);
  const split = useSelector(({ global }) => global.price?.split);

  const isPaymentPage = useRouterMatch(PAYMENT);

  return (
    <SharedSummary
      showDiscount={showDiscount}
      discountOpenByDefault={discountOpenByDefault}
      discountPercent={discountPercent}
      discountAmount={discountAmount}
      sendDiscountCode={sendDiscountCode}
      {...(isPaymentPage && { partialPrice: partial?.partial_price })}
      {...(isPaymentPage && { splitPrice: split?.split_price })}
      {...rest}
    />
  );
};

export default OrderSummaryView;

import { createTourOrderRequest } from "../redux/order/orderActions";
import { useHistoryNavigation, useReduxAction } from ".";
import { setSessionStorageProductId, setSessionStorageSourceLink } from "../storage/sessionStorage";
import { steps } from "../helpers/constants";
import { base64ToBytes } from "@tint_fe/helpers";

const useCreateTourOrderFromQueryParams = () => {
  const createTourOrder = useReduxAction(createTourOrderRequest);
  const history = useHistoryNavigation();

  return () => {
    let tourInfo = {};

    try {
      const search = new URLSearchParams(document.location.search);
      const tour = search.get("tour");

      search.delete("tour");

      tourInfo = tour ? JSON.parse(atob(tour)) : {};

      const sourceLink = tourInfo.source_link;
      const sourceLinkForOrder = `${window.location.origin}${window.location.pathname}?pid=${tourInfo.product_id}`;
      delete tourInfo.source_link;

      if (tourInfo?.custom_pickup_point) {
        tourInfo.custom_pickup_point = new TextDecoder().decode(base64ToBytes(tourInfo.custom_pickup_point));
      }

      if (tourInfo?.dropoff_location_name) {
        tourInfo.dropoff_location_name = new TextDecoder().decode(base64ToBytes(tourInfo.dropoff_location_name));
      }

      setSessionStorageProductId(tourInfo.product_id);
      setSessionStorageSourceLink(sourceLink);

      createTourOrder({
        body: {
          order: {
            product_items_attributes: { 0: tourInfo },
            source_link: sourceLinkForOrder,
          },
        },
        productType: steps.TOUR_REDIRECT,
        history: history,
      });
    } catch (err) {
      console.error(err);
    }
  };
};

export default useCreateTourOrderFromQueryParams;

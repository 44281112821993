import React, { useState } from "react";

import { Button, ControlledInput } from "@tint_fe/ui";
import { useForm } from "react-hook-form";
import { getGclIdFromLocalStorage, parseClasses, validationRule } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import { fetchUserCountryApi, postWidgetIssues } from "@tint_fe/api";
import { useParseSearchParams } from "../../../hooks";

const buildQueryParams = (ref_title, country) => {
  let query = "";
  const GCLID = getGclIdFromLocalStorage();
  if (ref_title) query += `details[ref_title]=${ref_title}&`;
  query += "details[ref_error_code]=product_unavailable&";
  if (country) query += `details[country]=${country}&`;
  if (GCLID) query += `&details[ref_context]=gcld.${GCLID}&`;
  query += `details[ref_url]=${window.location.href}`;

  return query;
};

const normalizeFormValues = (val) => {
  const { full_name, phone, email } = val;
  const first_name = full_name.split(" ").slice(0, 1).join(" ");
  const last_name = full_name.split(" ").slice(1).join(" ");
  const formattedPhone = phone.replace(/[^0-9+]/g, "");
  delete val.full_name;

  return {
    ...val,
    first_name,
    last_name,
    email: email.trim().toLowerCase(),
    phone: formattedPhone,
  };
};

const CustomerForm = () => {
  const { t } = useTranslation();
  const { elCls } = parseClasses({
    base: "notification",
  });
  const { control, handleSubmit } = useForm();
  const searchParams = useParseSearchParams();
  const { productId, pid, tourId, tourid, activityId, activityid } = searchParams || {};
  const handledProductId = productId || pid || tourId || tourid || activityId || activityid;

  const [successfullFormSubmitted, setSuccessfullFormSubmitted] = useState(false);

  const postWidgetIssuesFn = async (formValues) => {
    const country = await fetchUserCountryApi();
    const queryParam = buildQueryParams(handledProductId, country);
    await postWidgetIssues({
      body: { ...formValues },
      queryParam,
    });
  };

  const submitForm = handleSubmit((values) => {
    const formValues = normalizeFormValues(values);
    postWidgetIssuesFn(formValues);
    setSuccessfullFormSubmitted(true);
  });

  if (successfullFormSubmitted) {
    return (
      <div className="customer-form__row mg-t-3">
        <p>{"Your form has been submitted. Our team will contact you shortly."}</p>
      </div>
    );
  }

  return (
    <div className="customer-form__row mg-t-3">
      <ControlledInput
        control={control}
        classNameWrapper={elCls("input")}
        name="full_name"
        placeholder={"Your full name"}
        label={"Full Name*"}
        removeStartEndSpaces
        rules={{ ...validationRule.required("Full Name") }}
      />
      <ControlledInput
        control={control}
        classNameWrapper={elCls("input")}
        name="email"
        placeholder={"example@gmail.com"}
        label={"Email*"}
        rules={{
          ...validationRule.required(t("formField.emailAddress")),
          ...validationRule.email,
        }}
      />
      <ControlledInput
        control={control}
        classNameWrapper={elCls("input")}
        name="phone"
        type="tel"
        placeholder={"+1 (800) 555‑0175"}
        label={"Phone number*"}
        removeStartEndSpaces
        rules={{
          ...validationRule.required("Phone number"),
          ...validationRule.minLength(t("Phone number"), 10),
        }}
      />
      <Button
        small
        background="dark"
        onClick={submitForm}
        className={"error-page__contact-btn-unavailable mg-t-5 mg-r-0"}
      >
        {"Send"}
      </Button>
    </div>
  );
};

export default CustomerForm;

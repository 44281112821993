import React from "react";
import T from "prop-types";
import { useWatch } from "react-hook-form";

import { paymentMethods } from "@tint_fe/helpers";

import CreditCardPaymentMethod from "./CreditCardPaymentMethod/CreditCardPaymentMethod";
import ExternalMethodInfo from "./ExternalMethodInfo";

const PaymentMethod = ({
  control,
  baseClassName,
  partial_payments_enabled,
  partial_payment_amount,
  second_payment_charge_date,
  split_payment_enabled,
  split_payment_amount,
  cards,
  onPartialPayment,
  chargeCurrency,
}) => {
  const paymentMethod = useWatch({
    control,
    name: "payment_method",
    defaultValue: paymentMethods.CREDIT_CARD,
  });

  const renderPaymentMethod = () => {
    switch (paymentMethod) {
      case paymentMethods.CREDIT_CARD:
        return (
          <CreditCardPaymentMethod
            baseClassName={baseClassName}
            control={control}
            partial_payments_enabled={partial_payments_enabled}
            partial_payment_amount={partial_payment_amount}
            second_payment_charge_date={second_payment_charge_date}
            split_payment_enabled={split_payment_enabled}
            cards={cards ?? []}
            onPartialPayment={onPartialPayment}
            chargeCurrency={chargeCurrency}
            split_payment_amount={split_payment_amount}
          />
        );
      case paymentMethods.G_PAY:
        return <ExternalMethodInfo baseClassName={baseClassName} name="Google Pay" />;
      case paymentMethods.APPLE_PAY:
        return <ExternalMethodInfo baseClassName={baseClassName} name="Apple Pay" />;
      default:
        return null;
    }
  };

  return renderPaymentMethod();
};

PaymentMethod.propTypes = {
  control: T.object.isRequired,
  partial_payments_enabled: T.bool,
  partial_payment_amount: T.number,
  split_payment_enabled: T.bool,
  split_payment_amount: T.oneOfType([T.number, T.string]),
  baseClassName: T.string,
  cards: T.array,
  onPartialPayment: T.func,
  second_payment_charge_date: T.oneOfType([T.string, T.instanceOf(Date)]),
  chargeCurrency: T.string,
};

export default PaymentMethod;

import React, { useEffect, useRef, useState } from "react";
import T from "prop-types";
import RcTooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";

import { useBreakPoints } from "@tint_fe/hooks";

const Tooltip = ({
  text,
  children,
  containerId,
  showFirstTimeAutomatically,
  containerStyles,
  containerClassName,
  ...rest
}) => {
  const ref = useRef();
  const { isLg } = useBreakPoints();
  const [controlled, setControlled] = useState(!(typeof showOnce === "undefined"));

  useEffect(() => {
    if (controlled && showFirstTimeAutomatically) {
      const timer = setTimeout(() => setControlled(false), 1000);

      return () => clearTimeout(timer);
    }
  }, [controlled, showFirstTimeAutomatically]);

  useEffect(() => {
    if (showFirstTimeAutomatically) {
      ref.current.scrollIntoView({ block: "center", behavior: "smooth" });
      setControlled(true);
    }
  }, [showFirstTimeAutomatically]);

  return (
    <RcTooltip
      overlayStyle={{ maxWidth: 155 }}
      overlayClassName="tint-tooltip p4"
      getTooltipContainer={() => document.getElementById(containerId || "rc-tooltip-container")}
      overlay={text}
      autoAdjustOverflow={true}
      {...(controlled && { visible: showFirstTimeAutomatically })}
      align={{ offset: [isLg ? 0 : 44, -12] }}
      onClick={(e) => {
        if (!isLg) {
          e.stopPropagation();
        }
      }}
      trigger={isLg ? "click" : "hover"}
      {...rest}
    >
      <span style={containerStyles} ref={ref} className={containerClassName}>
        {children}
      </span>
    </RcTooltip>
  );
};

Tooltip.propTypes = {
  text: T.oneOfType([T.string, T.node]),
  children: T.node.isRequired,
  containerId: T.string,
  showOnce: T.bool,
  showFirstTimeAutomatically: T.bool,
  containerStyles: T.object,
  containerClassName: T.string,
};

export default Tooltip;

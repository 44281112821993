import React, { useEffect, useState } from "react";
import T from "prop-types";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";

import { useBreakPoints, useTranslation } from "@tint_fe/hooks";
import { parseClasses } from "@tint_fe/helpers";
import { Collapse, ControlledInput, Button } from "..";

const DiscountCode = ({ sendDiscountCode, percent, discountCode, setSendingDiscount, className, openByDefault }) => {
  const [sending, setSending] = useState(false);
  const [approved, setApproved] = useState(null);

  const { t } = useTranslation();
  const {
    control,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: { discount_code: discountCode },
  });

  const { isSm } = useBreakPoints();
  const [isOpen, setIsOpen] = useState(openByDefault);

  const { mainCls, elCls } = parseClasses({
    base: "discount-code",
    additional: ["mg-t-2", className],
  });

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const onSubmit = (val) => {
    setSending(true);
    setSendingDiscount && setSendingDiscount(true);

    sendDiscountCode({
      onSuccess: () => {
        setApproved(true);
        setSendingDiscount && setSendingDiscount(false);
        setSending(false);
        setIsOpen(false);
      },
      onError: (error) => {
        setSending(false);
        setSendingDiscount && setSendingDiscount(false);
        setError("discount_code", { type: "string", message: error.messages[0] });
      },
      order: val,
    });
  };

  useEffect(() => {
    if (discountCode) {
      setValue("discount_code", discountCode);
    }
  }, [discountCode, setValue]);

  return (
    <Collapse
      open={isOpen || isSm}
      onClick={handleOpen}
      icon={Number(percent) ? <span className={elCls("percent")}>-{percent}%</span> : null}
      header={
        (approved && isEmpty(errors)) || Number(percent) ? (
          <span className="p2-1">{t("appliedPromoCode")}</span>
        ) : (
          <span className="p2-1">{t("addPromoCode")}</span>
        )
      }
      className={mainCls}
      initialHeight={20}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={elCls("form")}>
        <ControlledInput
          error={errors?.discount_code}
          small
          disabled={sending}
          name="discount_code"
          control={control}
        />
        <Button disabled={sending} type="submit">
          {t("apply")}
        </Button>
      </form>
    </Collapse>
  );
};

DiscountCode.propTypes = {
  sendDiscountCode: T.func,
  percent: T.oneOfType([T.number, T.string]),
  discountCode: T.string,
  setSendingDiscount: T.func,
  className: T.string,
  openByDefault: T.bool,
};

export default DiscountCode;

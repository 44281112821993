import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { selectIfCustomPrice } from "../../../../redux/sharedSelectors";
import OrderSummaryView from "../../../components/OrderSummaryView";
import { CHECKOUT } from "../../../router";
import useTourStartLocation from "../../Tour/hooks/useTourStartLocation";
import useTourSummaryInfo from "../hooks/useTourSummaryInfo";
import { useAgentCommission } from "@tint_fe/hooks";

const TourSummary = () => {
  const orderData = useSelector(({ order }) => order.data);
  const { showAgentCommission, showAgentPays, isCusomerPAC } = useAgentCommission({ order: orderData });

  const { pathname } = useLocation();
  const price = useSelector((state) => state.global.price);
  const product = useSelector((state) => state.product.data);
  const requestedPrice = useSelector(({ order }) => order.dataForPayment.price_to_be_charged);
  const isCustomPrice = useSelector(selectIfCustomPrice);
  const lineItems = useTourSummaryInfo(price);
  const lateBookingSupplement = price?.product_items && price?.product_items[0]?.rounded_late_booking_supplement;
  const roundedTaxAmount = price?.product_items && price?.product_items[0]?.rounded_citizenship_tax_amount;
  const citizenshipTaxAmount = roundedTaxAmount || price.citizenship_tax_amount;
  const summaryTitle = orderData?.charge_strategy === "partial_agent_credit" ? "summary.titleAgent" : "summary.title";
  const isCheckout = pathname.includes(CHECKOUT);
  const { startLocation: tourStartLocation } = useTourStartLocation();

  const getRoundedTotalPrice = () => {
    if (isCusomerPAC) {
      return Math.ceil(Number(price.tint_charge_price));
    }

    return price.rounded_taxed_components_price;
  };

  return (
    <OrderSummaryView
      summaryTitle={summaryTitle}
      startLocation={isCheckout ? {} : tourStartLocation}
      name={product.display_name}
      img={product.cover_image}
      lineItems={lineItems}
      lateBookingSupplement={lateBookingSupplement}
      citizenshipTaxAmount={citizenshipTaxAmount}
      totalPrice={price?.tint_charge_price}
      requestedPrice={requestedPrice}
      roundedTotalPrice={getRoundedTotalPrice()}
      showSummaryDetails={!isCustomPrice && !isCusomerPAC}
      currency={price?.charge_currency}
      agentFeeAmount={showAgentCommission ? price?.agent_fee_amount : 0}
      agentPaysAmount={showAgentPays ? price?.tint_charge_price : 0}
    />
  );
};

export default TourSummary;

import React, { useEffect, useState } from "react";
import T from "prop-types";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

import { parseClasses, join, dates, checkCurrencyChar, bookingTypes, isPriceInSmallRange } from "@tint_fe/helpers";
import { useGetUser, useIsRequestPayment, useTranslation } from "@tint_fe/hooks";

import SummaryHint from "../SummaryHint";
import { ShoppingBasketSvg, ArrowRight, Calendar } from "../../../assets/icons";
import Collapse from "../../Collapse/Collapse";
import SummaryProductItems from "../SummaryProductItems";
import SummaryHotelsItems from "../SummaryHotelsItems";
import SummaryToursItems from "../SummaryToursItems";
import DiscountCode from "../../DiscountCode/DiscountCode";
import SummaryRooms from "../SummaryRooms";
import SummaryLateBooking from "../SummaryLateBooking";
import SummaryTaxes from "../SummaryTaxes";
import SummaryBreakfast from "../SummaryBreakfast";

const formatDate = (date, bookingType) => {
  return (
    date &&
    dates.format(date, "EEEE, LLLL dd, yyyy", ![bookingTypes.SMART_PACKAGE, bookingTypes.HOTEL].includes(bookingType))
  );
};

const formatTime = (date) => {
  return date && dates.format(date, "HH:mm", true);
};

const hideOrderDetailsBookingTypes = [bookingTypes.TOUR, bookingTypes.SMART_PACKAGE, bookingTypes.CUSTOM_PACKAGE];

const ABSharedSummary = ({
  isRtl,
  className,
  hints,
  name,
  showName,
  discountClassName,
  lineItems,
  lateBookingSupplement,
  hotels,
  tours,
  rooms,
  requestedPrice,
  partialPrice,
  splitPrice,
  openLabel,
  closeLabel,
  showDiscount,
  discountOpenByDefault,
  percent,
  discountAmount,
  discountCode,
  sendDiscountCode,
  roundedTotalPrice,
  citizenshipTaxAmount,
  startLocation,
  ticketOption,
  endLocation,
  isCustomPrice,
  totalPrice,
  currency,
  taxes,
  descriptions,
  descriptionsComponent,
  agentFeeAmount,
  agentPaysAmount,
  breakfastIncluded,
  hidePricesSection,
}) => {
  const { t } = useTranslation();
  const [sendingDiscount, setSendingDiscount] = useState();
  const currencyChar = checkCurrencyChar(currency);
  const { user = {} } = useGetUser();
  const showRequestedPriceAgent = user?.isAgent ? !!partialPrice || !!splitPrice : true;
  const bookingType = useSelector(({ global, order }) => global.bookingType || order.data?.build_type);

  const { mainCls, elCls } = parseClasses({
    base: "summary",
    classes: [isRtl && "rtl", "ab-test"],
    additional: [className],
  });

  const [open, setOpen] = useState(false);

  const toggleCollapse = () => setOpen((prev) => !prev);

  const showRequestedPrice =
    (typeof roundedTotalPrice !== "undefined" &&
      typeof requestedPrice !== "undefined" &&
      !sendingDiscount &&
      requestedPrice !== null &&
      Number(requestedPrice) !== Number(totalPrice)) ||
    partialPrice ||
    splitPrice;
  const sumBeforeDicount = Math.round(Number(roundedTotalPrice) + Number(discountAmount));

  const toBePaidPrice = Math.ceil(Number(partialPrice || splitPrice || requestedPrice));

  const isRequestPayment = useIsRequestPayment();
  const hideOrderDetails =
    hideOrderDetailsBookingTypes.includes(bookingType) &&
    isRequestPayment &&
    !isPriceInSmallRange(roundedTotalPrice, toBePaidPrice);
  const hideTotalPriceBundle =
    bookingType === bookingTypes.BUNDLE && isRequestPayment && !isPriceInSmallRange(totalPrice, toBePaidPrice);

  const showDescriptions = !isEmpty(descriptions) && bookingType === bookingTypes.HOTEL;

  const isOrderDetails = lineItems?.length || hotels?.length || tours?.length || rooms?.length || showRequestedPrice;

  const isActivity = bookingType === bookingTypes.ACTIVITY;

  const showDefaultLocation = !isActivity && (!isEmpty(startLocation) || !isEmpty(endLocation));

  useEffect(() => {
    if (showDiscount && !Number(percent)) {
      setTimeout(() => setOpen(true), 400);
    }
  }, [showDiscount]);

  const getPriceForDisplay = () => {
    let priceForDisplay = null;

    switch (true) {
      case !!Number(agentFeeAmount):
        priceForDisplay = Number(totalPrice).toFixed(2);
        break;
      case !!open:
        break;
      case !!partialPrice:
        priceForDisplay = Math.ceil(Number(partialPrice));
        break;
      case !!splitPrice:
        priceForDisplay = Math.ceil(Number(splitPrice));
        break;
      case showRequestedPrice:
        priceForDisplay = Math.ceil(Number(requestedPrice));
        break;
      case !!roundedTotalPrice && !Number.isNaN(Number(roundedTotalPrice)):
        priceForDisplay = Math.ceil(Number(roundedTotalPrice));
        break;
    }

    return priceForDisplay ? (
      <>
        {!!Number(percent) && showDiscount && (
          <span className={elCls("total-sum-before-discount", "p4")}>
            {currencyChar}
            {sumBeforeDicount}
          </span>
        )}
        <span className="bold">
          {currencyChar}
          {priceForDisplay}
        </span>
      </>
    ) : null;
  };

  return (
    <div className={mainCls}>
      <div className={elCls("title", "uppercase bold mg-b-2")}>{"Order summary:"}</div>
      {showName && <h4 className={elCls("ab-name", roundedTotalPrice && !isActivity && "mg-b-4")}>{name}</h4>}
      {isActivity && (!isEmpty(startLocation) || !isEmpty(ticketOption)) && (
        <div className={"mg-t-2"}>
          {!isEmpty(ticketOption) && (
            <div className={elCls("date", "summary__date", "mg-b-2")}>
              {ticketOption.ticketName ? `Ticket option: ${ticketOption.ticketName}` : ""}
            </div>
          )}
          <div className={elCls("date")}>
            <Calendar className="mg-r-2" />
            {t("Start")}: {formatDate(startLocation?.date, bookingType)}{" "}
            {startLocation?.name ? `${t("from")} ${startLocation.name}` : ""}
            {startLocation.time ? ` ${t("at")} ${formatTime(startLocation.time)}` : ""}
            {startLocation.stringTime ? ` ${t("at")} ${startLocation.stringTime}` : ""}
          </div>
        </div>
      )}

      {isOrderDetails && !isCustomPrice && (
        <Collapse
          className={elCls("collapse")}
          onClick={toggleCollapse}
          open={open}
          header={
            <span className={join(["head", "bold", open && "open"], " ")}>
              <ShoppingBasketSvg className="mg-r-2" />
              <span className="trim">{open ? openLabel : closeLabel}</span>
              <ArrowRight className="arrow mg-l-1 " />
            </span>
          }
          icon={getPriceForDisplay()}
        >
          {/*  start end locations */}
          {showDefaultLocation && (
            <div className={hideOrderDetails ? "mg-t-4" : elCls("dates", "mg-t-4")}>
              <div className={elCls("title", "uppercase bold mg-b-2")}>{"Dates & Locations"}</div>
              <div className={elCls("date", "mg-b-4")}>
                <Calendar className="mg-r-2" />
                {t("Start")}: {formatDate(startLocation?.date, bookingType)}{" "}
                {startLocation?.name ? `${t("from")} ${startLocation.name}` : ""}
                {startLocation.time ? ` ${t("at")} ${formatTime(startLocation.time)}` : ""}
              </div>

              {!isEmpty(endLocation) && (
                <div className={elCls("date", "summary__date__with-connect", "mg-b-4")}>
                  <Calendar className="mg-r-2" />
                  {t("End")}: {formatDate(endLocation?.date, bookingType)}{" "}
                  {endLocation?.name ? `${t("in")} ${endLocation.name}` : ""}
                </div>
              )}
            </div>
          )}

          {!hideOrderDetails && !hidePricesSection && (
            <>
              <div className={elCls("title", "uppercase bold mg-b-2")}>{"Prices"}</div>
              <SummaryProductItems lineItems={lineItems} classNameItem={elCls("item", "mg-b-1")} currency={currency} />
              <SummaryRooms rooms={rooms} classNameItem={elCls("item", "additional", "mg-t-1")} />
              <SummaryHotelsItems hotels={hotels} classNameItem={elCls("item", "additional", "mg-t-1")} />
              <SummaryToursItems tours={tours} classNameItem={elCls("item", "additional", "mg-t-1")} />
              <SummaryLateBooking
                price={lateBookingSupplement}
                classNameItem={elCls("item", "mg-t-1")}
                currency={currency}
              />
              <SummaryBreakfast breakfastIncluded={breakfastIncluded} />
            </>
          )}

          {!!roundedTotalPrice && !hideOrderDetails && !hideTotalPriceBundle && (
            <div className={elCls("total-sum", "bold uppercase mg-t-3")}>
              <span>{t("summary.totalCharge")}</span>
              <div>
                {!!Number(percent) && showDiscount && (
                  <span className={elCls("total-sum-before-discount")}>
                    {currencyChar}
                    {sumBeforeDicount}
                  </span>
                )}
                <span>
                  {currencyChar}
                  {roundedTotalPrice}
                </span>
              </div>
            </div>
          )}

          {showRequestedPrice && showRequestedPriceAgent && (
            <div className={elCls("total-sum", "bold uppercase mg-t-4")}>
              <span>{t("summary.toBePaid")}</span>
              <span>
                {currencyChar}
                {toBePaidPrice}
              </span>
            </div>
          )}

          {citizenshipTaxAmount > 0 && (
            <span className={elCls("citizenship-tax", "additional", "mg-t-2")}>
              <span>Included Israel VAT</span>
              <span className="text">
                {currencyChar}
                {Math.ceil(Number(citizenshipTaxAmount))}
              </span>
            </span>
          )}

          {!isEmpty(taxes) && (taxes.included_taxes || taxes.excluded_taxes) && (
            <SummaryTaxes
              className={elCls("taxes", showDiscount && "taxes-b-b", "mg-t-2")}
              taxes={taxes}
              currencyChar={currencyChar}
            />
          )}

          {!!Number(agentFeeAmount) && (
            <div className={elCls("agent-fee-container")}>
              {!!Number(agentFeeAmount) && (
                <div className={elCls("agent-fee", "bold uppercase mg-t-2 mg-b-2")}>
                  <span>{t("summary.agentFeeAmount")}</span>
                  <span>
                    {currencyChar}
                    {Number(agentFeeAmount).toFixed(2)}
                  </span>
                </div>
              )}
              {!!Number(agentPaysAmount) && (
                <div className={elCls("agent-fee", "bold uppercase mg-b-2")}>
                  <span>{t("summary.agentPays")}</span>
                  <span>
                    {currencyChar}
                    {Number(agentPaysAmount).toFixed(2)}
                  </span>
                </div>
              )}
            </div>
          )}

          {showDiscount && (
            <DiscountCode
              className={discountClassName}
              percent={percent}
              discountCode={discountCode}
              openByDefault={discountOpenByDefault}
              setSendingDiscount={setSendingDiscount}
              sendDiscountCode={sendDiscountCode}
            />
          )}
        </Collapse>
      )}

      {isCustomPrice && (
        <div className={elCls("total-sum", "bold uppercase mg-t-2")}>
          <span>{t("summary.totalCharge")}</span>
          <span>
            {currencyChar}
            {roundedTotalPrice}
          </span>
        </div>
      )}

      <SummaryHint hint={hints} small className={elCls("hint", "mg-t-2")} />
      {!!showDescriptions && descriptionsComponent}
    </div>
  );
};

ABSharedSummary.defaultProps = {
  lineItems: [],
  hotels: [],
  tours: [],
  name: "Product Name",
  showName: true,
  openLabel: "Hide order summary",
  closeLabel: "Show order summary",
  isCustomPrice: false,
  taxes: {},
};

ABSharedSummary.propTypes = {
  isRtl: T.bool,
  className: T.string,
  hints: T.oneOfType([T.string, T.array]),
  name: T.string,
  showName: T.bool,
  discountClassName: T.string,
  lineItems: T.array,
  hotels: T.array,
  tours: T.array,
  lateBookingSupplement: T.number,
  requestedPrice: T.oneOfType([T.number, T.string]),
  showDiscount: T.bool,
  discountOpenByDefault: T.bool,
  percent: T.oneOfType([T.number, T.string]),
  discountCode: T.string,
  discountAmount: T.number,
  sendDiscountCode: T.func,
  rooms: T.array,
  roomsClassName: T.string,
  partialPrice: T.number,
  splitPrice: T.number,
  openLabel: T.string,
  closeLabel: T.string,
  roundedTotalPrice: T.number,
  citizenshipTaxAmount: T.oneOfType([T.string, T.number]),
  startLocation: T.shape({
    date: T.oneOfType([T.string, T.instanceOf(Date)]),
    name: T.string,
    time: T.oneOfType([T.string, T.instanceOf(Date)]),
    stringTime: T.string,
  }),
  ticketOption: T.shape({
    ticketName: T.string,
  }),
  endLocation: T.shape({
    date: T.oneOfType([T.string, T.instanceOf(Date)]),
    name: T.string,
  }),
  isCustomPrice: T.bool,
  totalPrice: T.oneOfType([T.number, T.string]),
  currency: T.string,
  taxes: T.shape({
    included_taxes: T.number,
    excluded_taxes: T.number,
  }),
  descriptions: T.object,
  descriptionsComponent: T.node,
  agentFeeAmount: T.oneOfType([T.number, T.string]),
  agentPaysAmount: T.oneOfType([T.string, T.number]),
  breakfastIncluded: T.bool,
  hidePricesSection: T.bool,
};

export default ABSharedSummary;

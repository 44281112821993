import React, { useRef, forwardRef } from "react";
import T from "prop-types";
import { useDayPicker, useDayRender } from "react-day-picker";
import { BadgeLastSeats, BadgePopular } from "../../assets/icons";

const formatPrice = (price) => {
  if (price >= 10000) {
    return `${Math.floor(price / 1000)}k`;
  }

  if (price >= 1000) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return price;
};

const Button = forwardRef((props, ref) => {
  const { classNames, styles } = useDayPicker();
  const isDisabled = props.disabled || props.loading;

  const shouldHandleUnavailableDates =
    props.handleUnavailableDates && typeof props.handleUnavailableDates === "function";
  const classNamesArr = [classNames.button_reset, classNames.button];
  if (props.className) {
    classNamesArr.push(props.className);
  }
  if (shouldHandleUnavailableDates) {
    classNamesArr.push("rdp-day_disabled-with-hover");
  }

  const onClickHandler = () => {
    if (isDisabled && shouldHandleUnavailableDates) {
      props.handleUnavailableDates();
    } else {
      props.onClick();
    }
  };

  const propsWithoutHandleUnavailableDates = { ...props };
  if (shouldHandleUnavailableDates) {
    delete propsWithoutHandleUnavailableDates?.handleUnavailableDates;
    delete propsWithoutHandleUnavailableDates?.handleMinPrices;
    delete propsWithoutHandleUnavailableDates?.minPrice;
    delete propsWithoutHandleUnavailableDates?.currencyChar;
  }

  const className = classNamesArr.join(" ");
  const style = { ...styles.button_reset, ...styles.button, ...(props.loading && { cursor: "wait" }) };

  if (props.style) {
    Object.assign(style, props.style);
  }

  return (
    <button
      {...propsWithoutHandleUnavailableDates}
      onClick={onClickHandler}
      disabled={false}
      ref={ref}
      type="button"
      className={className}
      style={style}
    >
      {props.children}
      {props.handleMinPrices && (
        <p
          style={{
            padding: 0,
            margin: 0,
            fontSize: 14,
            color: "var(--color-green-3)",
            ...(!props.minPrice && props.loading && { filter: "blur(2.5px)" }),
          }}
        >
          {props.minPrice
            ? `${props.currencyChar}${formatPrice(props.minPrice)}`
            : props.loading
            ? `${props.currencyChar}${formatPrice(props.currencyChar === "¥" ? 123456 : 1234)}`
            : "\u00A0"}
        </p>
      )}
    </button>
  );
});

const ABDay = ({
  date,
  displayMonth,
  isLastSeats,
  isPopular,
  isSpotlighted,
  handleMinPrices,
  minPrice,
  currencyChar,
  isMd,
  loading,
  handleUnavailableDates,
}) => {
  const buttonRef = useRef(null);
  const dayRender = useDayRender(date, displayMonth, buttonRef);

  if (dayRender.isHidden) {
    return <></>;
  }
  if (!dayRender.isButton) {
    return <div {...dayRender.divProps} />;
  }

  const getLableLeftStyles = () => {
    return isPopular ? 0 : -5;
  };

  const size = isMd ? 48 : 60;

  return (
    <div style={{ position: "relative" }}>
      <Button
        name="day"
        ref={buttonRef}
        {...dayRender.buttonProps}
        onClick={loading ? null : dayRender.buttonProps.onClick}
        style={{
          display: "flex",
          flexDirection: "column",
          width: size,
          height: size,
          maxWidth: size,
          margin: 2,
          fontSize: "var(--fs-5)",
          borderWidth: 0,
          ...(isSpotlighted && {
            outline: "2px solid var(--extra-color-50)",
            outlineOffset: "-2px",
          }),
        }}
        handleMinPrices={handleMinPrices}
        minPrice={minPrice}
        currencyChar={currencyChar}
        loading={loading}
        handleUnavailableDates={handleUnavailableDates}
      />
      {(isPopular || isLastSeats) && (
        <div
          onClick={dayRender?.buttonProps?.onClick}
          style={{
            display: "flex",
            zIndex: 1,
            position: "absolute",
            top: 33,
            borderRadius: 2,
            overflow: "hidden",
            cursor: "pointer",
            ...{ left: getLableLeftStyles() },
          }}
        >
          {isPopular && <BadgePopular />}
          {isLastSeats && <BadgeLastSeats />}
        </div>
      )}
    </div>
  );
};

Button.propTypes = {
  className: T.string,
  style: T.object,
  children: T.node,
  handleMinPrices: T.bool,
  minPrice: T.number,
  currencyChar: T.string,
  loading: T.bool,
  handleUnavailableDates: T.func,
  disabled: T.bool,
  onClick: T.func,
};

ABDay.propTypes = {
  date: T.instanceOf(Date),
  displayMonth: T.instanceOf(Date),
  isPopular: T.bool,
  isSpotlighted: T.bool,
  isLastSeats: T.bool,
  handleMinPrices: T.oneOfType([T.func, T.bool]),
  minPrice: T.number,
  currencyChar: T.string,
  isMd: T.bool,
  loading: T.bool,
  handleUnavailableDates: T.func,
};

export default ABDay;

import * as React from "react";
const Card = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={28} fill="none" {...props}>
    <path
      fill="#f5854d"
      d="M25.502.799h-21a4.405 4.405 0 0 0-4.4 4.4v13.46c0 2.426 1.974 4.4 4.4 4.4h11.34a.9.9 0 0 0 0-1.8H4.502a2.603 2.603 0 0 1-2.6-2.6v-6.98h26.2v2.99a.9.9 0 0 0 1.8 0v-9.47c0-2.426-1.974-4.4-4.4-4.4Zm-21 1.8h21c1.434 0 2.6 1.167 2.6 2.6v.597h-26.2v-.597c0-1.433 1.166-2.6 2.6-2.6Zm-2.6 7.28V7.596h26.2V9.88h-26.2v-.001Z"
    />
    <path
      fill="#f5854d"
      d="M5.31 15.366h2.757a.9.9 0 0 0 0-1.8H5.31a.9.9 0 0 0 0 1.8ZM5.31 18.755h5.514a.9.9 0 0 0 0-1.8H5.31a.9.9 0 0 0 0 1.8ZM20.093 17.391a5.709 5.709 0 0 0-1.683 4.063c0 1.535.598 2.978 1.683 4.063a5.71 5.71 0 0 0 4.063 1.683 5.709 5.709 0 0 0 4.063-1.683 5.754 5.754 0 0 0 0-8.125 5.751 5.751 0 0 0-8.126 0Zm6.853 6.853c-1.49 1.49-4.09 1.491-5.58 0a3.916 3.916 0 0 1-1.156-2.79 3.92 3.92 0 0 1 1.156-2.79 3.933 3.933 0 0 1 2.79-1.155c1.011 0 2.021.385 2.79 1.154a3.952 3.952 0 0 1 0 5.581Z"
    />
    <path
      fill="#f5854d"
      d="M25.966 19.643a.9.9 0 0 0-1.273 0l-.537.536-.537-.536a.9.9 0 1 0-1.273 1.272l.537.537-.537.537a.898.898 0 0 0 .637 1.536.897.897 0 0 0 .637-.264l.537-.536.537.536a.897.897 0 0 0 1.274 0 .898.898 0 0 0 0-1.272l-.537-.537.537-.537a.902.902 0 0 0-.002-1.272Z"
    />
  </svg>
);
export default Card;

import React, { useEffect, useRef } from "react";
import T from "prop-types";
import { isEmpty, isObject } from "lodash";

import { parseClasses } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";
import { OverflowValueIcon, ValidateMarks, LoaderMark } from "..";
import { HorizontalDots, Info4 } from "../../assets/icons";

const InputWrapper = React.forwardRef(
  (
    {
      children,
      classNameWrapper,
      disabled,
      error,
      errorClasses,
      focus,
      id,
      indent,
      isContentOverflow,
      label,
      loading,
      nextIcon,
      cardInput,
      onClick,
      onMouseEnter,
      onMouseLeave,
      outline,
      prevIcon,
      remark,
      scrollIntoViewOnError,
      showValidateMark,
      small,
      type,
      value,
      withValue,
      withoutOverflowTooltip,
      wrapperId,
      willHaveDropdownIcon,
      useT,
    },
    externalRef,
  ) => {
    const { t, isRtl } = useTranslation();
    const ref = useRef();
    const isError = !isEmpty(error);

    const { mainCls, elCls } = parseClasses({
      base: "input-wrapper",
      classes: [
        type,
        isRtl && "rtl",
        focus && "focus",
        small && "small",
        isError && "error",
        outline && "outline",
        disabled && "disabled",
        prevIcon && "prev-icon",
        nextIcon && "next-icon",
        withValue && "with-value",
        isContentOverflow && "with-overflow-icon-tooltip",
      ],
      additional: classNameWrapper,
    });

    const { mainCls: containerClass } = parseClasses({
      base: "input-content",
      classes: [type],
      additional: [elCls("container")],
    });

    const { mainCls: errorClass } = parseClasses({
      base: "error-message",
      additional: errorClasses,
    });

    const handleErrorMessage = (err) => {
      if (isObject(err.messages)) {
        return t(err.messages.text, { fieldName: useT ? t(err.messages.fieldName) : err.messages.fieldName });
      }

      if (isObject(err.message)) {
        return t(err.message.text, { fieldName: useT ? t(err.message.fieldName) : err.message.fieldName });
      }

      if (typeof err.messages === "string") {
        return t(err.messages);
      }

      if (typeof err.message === "string") {
        return t(err.message);
      }

      if (err.text) {
        return t(err.text, { fieldName: t(err.fieldName) });
      }

      return t(err);
    };

    useEffect(() => {
      if (scrollIntoViewOnError && error) {
        const timer = setTimeout(() => {
          ref.current?.scrollIntoView({ block: "center", behavior: "smooth" });
        }, 100);

        return () => clearTimeout(timer);
      }
    }, [error, scrollIntoViewOnError]);

    const container = withoutOverflowTooltip ? (
      <div className={containerClass}>
        {prevIcon && <div className="input-icon prev-icon">{prevIcon}</div>}
        {children}
        {loading && <LoaderMark />}
        {showValidateMark && !nextIcon && isError && <ValidateMarks indent={indent} />}
        {nextIcon && <div className={`input-icon${cardInput ? "-card" : ""} next-icon ${isRtl}`}>{nextIcon}</div>}
      </div>
    ) : (
      <div className={containerClass}>
        {prevIcon && <div className="input-icon prev-icon">{prevIcon}</div>}
        {children}
        <OverflowValueIcon
          className={`${elCls("overflow-tooltip")} ${willHaveDropdownIcon && elCls("overflow-tooltip-with-next")}`}
          isContentOverflow={isContentOverflow}
          value={value}
          ignoreBreakpoints
          icon={<HorizontalDots height={14} width={14} />}
        />
        {loading && <LoaderMark />}
        {showValidateMark && !nextIcon && isError && <ValidateMarks indent={indent} />}
        {nextIcon && <div className={`input-icon${cardInput ? "-card" : ""} next-icon ${isRtl}`}>{nextIcon}</div>}
      </div>
    );

    return (
      <label
        id={wrapperId}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        htmlFor={id}
        ref={ref}
        onClick={(e) => {
          externalRef?.current?.focus();
          onClick && onClick(e);
        }}
        className={mainCls}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={elCls("label-row")}>
          {label && useT && <span className={elCls("label")}>{t(label)}</span>}
          {label && !useT && <span className={elCls("label")}>{label}</span>}
          {remark && (
            <OverflowValueIcon
              className={elCls("remark-icon")}
              isContentOverflow={true}
              value={remark}
              ignoreBreakpoints
              icon={<Info4 height={16} width={16} />}
            />
          )}
        </div>
        {container}
        {isError && <span className={errorClass}>{handleErrorMessage(error)}</span>}
      </label>
    );
  },
);

InputWrapper.defaultProps = {
  useT: true,
};

InputWrapper.propTypes = {
  children: T.node.isRequired,
  classNameWrapper: T.string,
  disabled: T.bool,
  error: T.object,
  errorClasses: T.string,
  focus: T.bool,
  id: T.string,
  indent: T.bool,
  isContentOverflow: T.bool,
  label: T.string,
  loading: T.bool,
  nextIcon: T.node,
  cardInput: T.bool,
  onClick: T.func,
  onMouseEnter: T.func,
  onMouseLeave: T.func,
  outline: T.bool,
  prevIcon: T.node,
  remark: T.string,
  scrollIntoViewOnError: T.bool,
  showValidateMark: T.bool,
  small: T.bool,
  type: T.string,
  value: T.oneOfType([T.object, T.number, T.string]),
  withValue: T.bool,
  withoutOverflowTooltip: T.bool,
  wrapperId: T.string,
  willHaveDropdownIcon: T.bool,
  useT: T.bool,
};

export default InputWrapper;

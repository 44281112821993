import React, { useEffect } from "react";
import T from "prop-types";
import { useFormContext, Controller } from "react-hook-form";

import SelectField from "../../../components/Form/SelectField";
import { useTranslation } from "@tint_fe/hooks";

const SPBucketLevel = ({ options, name, fieldLabel, placeholder }) => {
  const { control, setValue, getValues } = useFormContext();
  const { t } = useTranslation();

  const normalizeOptions = (values) => {
    return values?.map(({ label, ...rest }) => ({
      label: t(label),
      ...rest,
    }));
  };

  const handleChange = (val) => {
    return val.value;
  };

  const normalizedOptions = normalizeOptions(options);

  useEffect(() => {
    const prevVal = getValues(name);
    if (prevVal) return;

    setValue(name, options[0]?.value);
  }, [normalizedOptions]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ...restFiled }, fieldState: { error } }) => {
        return (
          <SelectField
            options={normalizedOptions}
            error={error}
            onChange={(val) => onChange(handleChange(val))}
            label={fieldLabel}
            placeholder={placeholder}
            remark={
              "We hand pick hotels based on location, traveler feedback, and facilities. As star ratings are not used everywhere, we also rely on common rating sources to help categorize our hotels. If you prefer to change the selected hotel, you will be able to do so at the next step."
            }
            {...restFiled}
          />
        );
      }}
    />
  );
};

SPBucketLevel.propTypes = {
  options: T.array,
  name: T.string,
  fieldLabel: T.string,
  placeholder: T.string,
};

export default SPBucketLevel;

import React from "react";
import T from "prop-types";

import { parseClasses, QueryString } from "@tint_fe/helpers";

import PaymentMethodSwitcher from "./PaymentMethodSwitcher";
import PaymentMethod from "./PaymentMethod";

const PaymentForm = ({
  control,
  className,
  partial_payments_enabled,
  partial_payment_amount,
  second_payment_charge_date,
  split_payment_enabled,
  cards = [],
  onPartialPayment,
  integrationCode,
  onSwitchPaymentMethod,
  chargeCurrency,
  split_payment_amount,
}) => {
  const { mainCls, elCls } = parseClasses({
    base: "payment-form",
    additional: [className, "mg-md-b-6"],
  });
  const { source } = QueryString.parse(document.location.search);
  const isStaffPayment = source === "staff_payment";

  return (
    <div className={mainCls}>
      {!isStaffPayment && (
        <PaymentMethodSwitcher
          control={control}
          onSwitch={onSwitchPaymentMethod}
          className={elCls("mod-switcher")}
          radioClassName={elCls("radio-btn")}
          integrationCode={integrationCode}
        />
      )}
      <PaymentMethod
        control={control}
        baseClassName="payment-form"
        partial_payments_enabled={partial_payments_enabled}
        partial_payment_amount={partial_payment_amount}
        split_payment_enabled={split_payment_enabled}
        second_payment_charge_date={second_payment_charge_date}
        cards={cards}
        onPartialPayment={onPartialPayment}
        chargeCurrency={chargeCurrency}
        split_payment_amount={split_payment_amount}
      />
    </div>
  );
};

PaymentForm.propTypes = {
  control: T.object.isRequired,
  partial_payments_enabled: T.bool,
  partial_payment_amount: T.number,
  split_payment_enabled: T.bool,
  className: T.string,
  cards: T.array,
  onPartialPayment: T.func,
  integrationCode: T.string,
  onSwitchPaymentMethod: T.func,
  second_payment_charge_date: T.oneOfType([T.string, T.instanceOf(Date)]),
  chargeCurrency: T.string,
  split_payment_amount: T.oneOfType([T.number, T.string]),
};

export default PaymentForm;

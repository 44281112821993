import React from "react";
import T from "prop-types";

import { useTranslation } from "@tint_fe/hooks";
import { parseClasses, checkIsSafari, checkIsExternalPaymentMethodAllowed } from "@tint_fe/helpers";

import {
  TintProsBill,
  TintProsDiamond,
  TintProsSmiley,
  TintProsHeart,
  TintProsChooseHotel,
  TintProsThumbUp,
  TintProsRates,
  GooglePayMarkIcon,
  ApplePayBtnIcon,
  AmexLogo34,
  MasterCardLogo34,
  VisaLogo34,
  InfoDiscount,
} from "../../assets/icons";

const iconsMap = {
  "tintPros.partialPayment": <InfoDiscount />,
  "tintPros.chooseHotel": <TintProsChooseHotel />,
  "tintPros.handPicked": <TintProsThumbUp />,
  "tintPros.exclusiveRates": <TintProsRates />,
  "tintPros.noHiddenFees": <TintProsDiamond />,
  "tintPros.bestPrice": <TintProsBill />,
  "tintPros.instantConfirmation": <TintProsHeart />,
  "tintPros.easyCancellation": <TintProsSmiley />,
};

const TintPros = ({ list, className, integrationCode }) => {
  const { t, isRTL, i18n } = useTranslation();

  const isExternalPaymentMethodsAllow = checkIsExternalPaymentMethodAllowed(integrationCode);

  const isSafari = checkIsSafari();
  const showApplePayIcon = isExternalPaymentMethodsAllow && isSafari;
  const includesPartialPayment = list.some((el) => el.text === "tintPros.partialPayment");

  const { mainCls, elCls } = parseClasses({
    base: "tint-pros",
    additional: [isRTL && "rtl", className],
  });

  return (
    <div dir={isRTL ? "rtl" : "auto"} className={mainCls}>
      <p className={elCls("heading", "bold", "mg-0", "mg-b-3", isRTL && "rtl")}>{t("tintPros.title")}</p>
      <div className={elCls("list")}>
        {list.map((el) => (
          <React.Fragment key={el.id}>
            <div className={elCls("list-item", "mg-b-2")}>
              {iconsMap[el.text] || <TintProsSmiley />}
              <div className={elCls("list-item-content")}>
                <span className={elCls("list-item-text")}>{el.ignoreI18 ? el.text : t(el.text)}</span>
                {i18n.exists(`${el.text}.descr`) && (
                  <span className={elCls("list-item-subtext")}>{t(`${el.text}.descr`)}</span>
                )}
                {el.cancellationLink && (
                  <span className={elCls("cancel")}>
                    <a href={el.cancellationLink} target="_blank" rel="noopener noreferrer">
                      {t("cancellationPolicy")}
                    </a>
                  </span>
                )}
                {el.additionalText && (
                  <span className={elCls("list-item-text-additional", "mg-t-1", "mg-l-2")}>{el.additionalText}</span>
                )}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      {includesPartialPayment && (
        <span className={elCls("list-item-subtext")}>{t("tintPros.partialPayment.explaination")}</span>
      )}
      <div className={elCls("desktop")}>
        <p className={elCls("payment", "mg-t-4", "mg-b-4")}>{t("tintPros.acceptedPaymentTypes")}</p>

        <div className={elCls("methods", "mg-b-4")}>
          <VisaLogo34 />
          <AmexLogo34 />
          <MasterCardLogo34 />
          {showApplePayIcon && <ApplePayBtnIcon height={24} width={38} />}
          {isExternalPaymentMethodsAllow && <GooglePayMarkIcon height={24} width={45} />}
        </div>

        <div className={elCls("secure")}>
          <svg style={{ width: "11px", height: "14px" }} viewBox="0 0 24 24">
            <path
              fill="#191716"
              d="M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1M12,7C13.4,7 14.8,8.1 14.8,9.5V11C15.4,11 16,11.6 16,12.3V15.8C16,16.4 15.4,17 14.7,17H9.2C8.6,17 8,16.4 8,15.7V12.2C8,11.6 8.6,11 9.2,11V9.5C9.2,8.1 10.6,7 12,7M12,8.2C11.2,8.2 10.5,8.7 10.5,9.5V11H13.5V9.5C13.5,8.7 12.8,8.2 12,8.2Z"
            />
          </svg>
          <p>100% {t("tintPros.securePayment")}</p>
        </div>
      </div>
    </div>
  );
};

TintPros.propTypes = {
  list: T.array,
  className: T.string,
  integrationCode: T.string,
};

export default TintPros;

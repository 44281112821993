export const checkCurrencyChar = (currency = "USD", useShortVersion = false) => {
  switch (currency) {
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "JPY":
      return "¥";
    case "CAD":
      return useShortVersion ? "$" : "CA$";
    case "AUD":
      return useShortVersion ? "$" : "AU$";
    default:
      return "$";
  }
};

import React from "react";
import T from "prop-types";

import { useBreakPoints } from "@tint_fe/hooks";
import { Tooltip } from "..";

const OverflowValueTooltip = ({ isContentOverflow, value, children, desktop, ignoreBreakpoints, ...rest }) => {
  const { isLg } = useBreakPoints();
  const preventToShow = !isContentOverflow || (desktop && isLg);
  const forceShow = isContentOverflow && ignoreBreakpoints && !!value;
  const hide = preventToShow && !forceShow;

  return (
    <Tooltip
      containerStyles={{ width: "100%" }}
      text={value}
      {...(hide && { visible: false })}
      placement={isLg ? "top" : "topRight"}
      {...rest}
      overlayStyle={{ maxWidth: isLg ? Math.min(310, window.innerWidth - 40) : 350 }}
    >
      {children}
    </Tooltip>
  );
};

OverflowValueTooltip.defaultProps = {
  // desktop - mean that this tooltip will work only for desktop, if false only for mobile
  desktop: false,
  isContentOverflow: false,
  ignoreBreakpoints: false,
};

OverflowValueTooltip.propTypes = {
  isContentOverflow: T.bool,
  value: T.string,
  children: T.node.isRequired,
  desktop: T.bool,
  ignoreBreakpoints: T.bool,
};

export default OverflowValueTooltip;

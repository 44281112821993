import React, { useRef, useState } from "react";
import T from "prop-types";

import { useOutsideClickHandler } from "@tint_fe/hooks";
import {
  checkCurrencyChar,
  checkIsExternalPaymentMethodAllowed,
  checkIsHttpsProtocol,
  checkIsSafari,
  parseClasses,
} from "@tint_fe/helpers";

const SplitPaymentNotificationUI = ({
  handleClickOnBtn,
  containerClassName,
  closeToast,
  chargeCurrency = "USD",
  splitPaymentAmount,
  sendRequestedPaymentType,
  integrationCode = "TI",
  partial_payments_enabled,
}) => {
  const ref = useRef();
  const { elCls } = parseClasses({
    base: "notification",
    additional: [containerClassName],
  });
  const [additionalEmailRequestSent, setAdditionalEmailRequestSent] = useState(false);
  const isHttpsProtocol = checkIsHttpsProtocol();
  const IsExternalPaymentMethodsAllow = checkIsExternalPaymentMethodAllowed(integrationCode);
  const isSafari = checkIsSafari();
  const showGooglePaySwitcher = isHttpsProtocol && IsExternalPaymentMethodsAllow;
  const showApplePaySwitcher = isHttpsProtocol && isSafari && IsExternalPaymentMethodsAllow;

  const currencyChar = checkCurrencyChar(chargeCurrency);

  useOutsideClickHandler(ref, () => null);

  const handleBtnClick = () => {
    if (handleClickOnBtn) {
      handleClickOnBtn();
    }
    closeToast();
  };

  const simplyClose = () => {
    closeToast();
  };

  const handleSendRequestedPaymentType = (type) => {
    sendRequestedPaymentType(type);
    setAdditionalEmailRequestSent(type);
  };

  if (additionalEmailRequestSent) {
    return (
      <div ref={ref} className={elCls("content")}>
        <p className="mg-0 p4 bold mg-b-2">
          {additionalEmailRequestSent === "PayPal"
            ? "Thank you! Our team has received your request for assistance with PayPal payment. We’ll reach out shortly to help complete your purchase."
            : "Thank you! Your request for bank transfer assistance has been sent. Our team will be in touch soon to send over further instructions."}
        </p>
      </div>
    );
  }

  return (
    <div ref={ref} className={elCls("content")}>
      <p className="mg-0 p4 bold mg-b-2">{"Uh oh! There was an error processing your payment."}</p>
      <p className="mg-0 p4 bold mg-b-2">
        {
          "We have noticed that you were unable to complete payment for this reservation. There was no charge to your account."
        }
      </p>
      <p className="mg-0 p4 mg-b-2">
        {
          "To complete your booking, we recommend verifying with your bank or authentication app as 3D Secure approval is often required. "
        }
        <span className={elCls("underlay-price-text")} onClick={simplyClose}>
          {"Click here"}
        </span>
        {" to make the payment again with the same or a different credit card."}
      </p>

      <p className="mg-0 p4 bold mg-b-2">
        {"If you're still unable to process the payment, we offer alternative options below:"}
      </p>

      {/* 1. */}
      <p className="mg-0 p4 mg-b-2 mg-l-2">
        <span className="bold">{"1. Mobile Wallets:"}</span>
        {" Use "}
        {showApplePaySwitcher && (
          <span className={elCls("underlay-price-text")} onClick={simplyClose}>
            {"Apple Pay"}
          </span>
        )}
        {showGooglePaySwitcher && showApplePaySwitcher ? " or " : ""}
        {showGooglePaySwitcher && (
          <span className={elCls("underlay-price-text")} onClick={simplyClose}>
            {"Google Pay"}
          </span>
        )}

        {" for a convenient and secure transaction."}
      </p>

      {/* 2. */}
      {partial_payments_enabled && splitPaymentAmount && (
        <p className="mg-0 p4 mg-b-2 mg-l-2">
          <span className="bold">{"2. Deposit:"}</span>
          {" Secure your booking with a deposit of "}
          <span className={elCls("price-text")}>
            {currencyChar}
            {splitPaymentAmount}
          </span>
          {". This will guarantee your spot and current pricing. You can complete the remaining payment within 7 days."}
          <span onClick={handleBtnClick} className={elCls("price-text")}>
            {" Link to payment"}
          </span>
        </p>
      )}

      {/* 3. */}
      <p className="mg-0 p4 mg-b-2 mg-l-2">
        <span className="bold">
          {partial_payments_enabled ? "3." : "2."}
          {" PayPal:"}
        </span>
        {" Please "}
        <span className={elCls("price-text")} onClick={() => handleSendRequestedPaymentType("PayPal")}>
          {"click here to request"}
        </span>
        {" PayPal, and our team will assist you with the process."}
      </p>

      {/* 4. */}
      <p className="mg-0 p4 mg-b-2 mg-l-2">
        <span className="bold">
          {partial_payments_enabled ? "4." : "3."}
          {" Bank Wire Transfer:"}
        </span>
        {" Please "}
        <span className={elCls("price-text")} onClick={() => handleSendRequestedPaymentType("Bank Wire Transfer")}>
          {"click here to request"}
        </span>
        {" a bank wire transfer and we will send over further instructions."}
      </p>
    </div>
  );
};

SplitPaymentNotificationUI.defaultProps = {
  textBtn: "process this payment",
};

SplitPaymentNotificationUI.propTypes = {
  containerClassName: T.string,
  hideBtn: T.bool,
  handleClickOnBtn: T.func,
  textBtn: T.string,
  children: T.node,
  closeToast: T.func,
  message: T.string,
  refErrorCode: T.string,
  orderNumber: T.string,
  chargeCurrency: T.string,
  splitPaymentAmount: T.number,
  sendRequestedPaymentType: T.func,
  integrationCode: T.string,
  partial_payments_enabled: T.bool,
};

export default SplitPaymentNotificationUI;
